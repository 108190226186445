import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import TimeEntryProfileEntity from "../entity";

export default class TimeEntryProfileList extends DefaultController {
    tableElem: any;
    datatable: any;
    private editModal: any;
    private editMembershipsModal: any;

    editMembershipsId = 0;
    editMembershipsModalTable: any = null

    async init() {
        this.entity = "time_entry_profiles";
        this.tableElem = jQuery('.datatables-users')
        this.editModal = new bootstrap.Modal((document.querySelector("#editTimeEntryProfile") as HTMLElement));
        this.editMembershipsModal = new bootstrap.Modal((document.querySelector("#editTimeEntryProfileMembers") as HTMLElement));
        this.createTable();
        await super.init();
    }

    getEntityData(elem: any) {
        return TimeEntryProfileEntity.getEntityData(elem)
    }

    bindListeners() {
        this.tableElem.delegate(".delete-record", "click", async (e: any) => {const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary me-3',
                cancelButton: 'btn btn-label-secondary'
            },
            buttonsStyling: false
        })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('time_entry_profile.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else if (r.status === 422) {
                    this.toastr.error(`${Utils.translate('time_entry_profile.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                } else {
                    this.toastr.error(`${Utils.translate('time_entry_profile.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });
        this.tableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            const form = (document.querySelector("#editTimeEntryProfileForm") as HTMLFormElement);
            form.reset();
            if (requestData.status === 200) {
                const data = requestData.data[0]
                jQuery("#editTimeEntryProfileForm #time_entry_profile_superior_id").val("").trigger("change").html("");
                await Utils.updateElements(data, '', form)
                if (data.superior && data.superior.name) {
                    const $newOption = jQuery("<option selected='selected'></option>").val(data.superior.uuid).text(data.superior.name)
                    jQuery("#editTimeEntryProfileForm #time_entry_profile_superior_id").append($newOption).trigger('change');
                }
                this.editModal.show();
            }

        });
        (document.querySelector("#addNewTimeEntryProfileForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#addNewTimeEntryProfileForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddTimeEntryProfile") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    elem.reset();
                    this.toastr.success(`${Utils.translate('time_entry_profile.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });

        (document.querySelector("#editTimeEntryProfileForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editTimeEntryProfileForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.datatable.ajax.reload();
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('time_entry_profile.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });

        (document.querySelector("#editTimeEntryProfileMembersForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editTimeEntryProfileMembersForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                await Utils.showLoader();
                const r = await Utils.entity.request(`/api/v1/time_entry_profiles/${this.editMembershipsId}/add_users`, 'POST', {
                    groupIds: jQuery("#time_entry_profile_new_users").val(),
                    valid_from: (elem.querySelector("#time_entry_profile_valid_from") as HTMLInputElement).value
                });

                if (r.status === 200) {
                    await Utils.hideLoader();
                    await this.datatable.ajax.reload();
                    this.editMembershipsModal.hide();
                    this.toastr.success(`${Utils.translate('time_entry_profile.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });



        jQuery(this.tableElem).delegate(".time_entry_profile_members", "click", async (e: any) => {
            const id = e.target.getAttribute("data-id")
            const timeEntryProfile = await Utils.entity.get(id, this.entity)
            if (timeEntryProfile.status === 200) {
                const data = timeEntryProfile.data[0]
                if(this.editMembershipsModalTable) {
                    this.editMembershipsModalTable.destroy();
                }
                this.editMembershipsId = id;
                const tableElem = document.querySelector(".datatables-time-entry-profile-add-users tbody") as HTMLTableElement
                tableElem.innerHTML = "";
                data.users.forEach((user: any) => {
                    const tr = `<tr>
                <td>${user.name}</td>
                <td>${new Date(Date.parse(user.from)).toLocaleDateString()}</td>
                <td>${new Date(Date.parse(user.to)).toLocaleDateString()}</td>
                </tr>`
                    tableElem.innerHTML = tableElem.innerHTML + tr;
                });
                this.editMembershipsModalTable = jQuery(".datatables-time-entry-profile-add-users").DataTable({
                    dom:
                        '<"row me-2 align-items-center"' +
                        '<"col-md-2"<"me-3 m-3"l>>' +
                        '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                        '>t' +
                        '<"row mx-2 align-items-center justify-content-between"' +
                        '<"col-6"i>' +
                        '<"col-2 mt-3"p>' +
                        '>',
                    language: {
                        sLengthMenu: '_MENU_',
                        search: '',
                        searchPlaceholder: `${Utils.translate('generic.search')}...`,
                        "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                        "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                        "paginate": {
                            "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                            "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                            "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                            "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                        },
                        "info":           `${Utils.translate('generic.datatable.info.info')}`,
                        "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                        "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
                    },
                    buttons: []
                });
                this.editMembershipsModal.show();
            }
        })
    }

    onSelectFilterResults(data: any, entity: any, element: any) {

        return data;
    }

    createTable() {
        this.datatable = this.tableElem.DataTable({
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/time_entry_profiles`,
            columns: [
                {data: 'name'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<button class="btn btn-secondary time_entry_profile_members" data-id="${full.uuid}">${full.current_users.length}</button>`;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.superior && full.superior.uuid) {
                            return full.superior.name;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 3,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddTimeEntryProfile'
                    }
                }
            ]
        });
    }
}